.log-submit-btn {
  --btn-height: 36px;
  --short-duration: 150ms;
  --medium-duration: 300ms;
  --success-color: #46a345;
}

.animate-authoring {
  animation: shrink var(--short-duration) ease-in-out forwards,
    pulse 800ms linear var(--short-duration) infinite;
}

.animate-text {
  animation: fade var(--medium-duration) ease-in-out forwards;
  overflow: hidden;
  white-space: nowrap;
}

.animate-login-ok {
  animation: success var(--medium-duration) ease-in-out forwards;
}

.log-submit-btn .checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke-width: 3;
  stroke: #fff;
  stroke-miterlimit: 10;
  width: var(--btn-height);
  height: var(--btn-height);
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}

.log-submit-btn svg.checkmark-animation {
  animation: stroke var(--medium-duration) ease-in-out 0.2s forwards;
}

@keyframes stroke {
  20% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.15);
  }
  66% {
    transform: scale(0.85);
  }
}

@keyframes shrink {
  0% {
  }
  25% {
  }
  50% {
  }
  100% {
    width: var(--btn-height);
    border-radius: 10000px;
  }
}

@keyframes success {
  0% {
    width: var(--btn-height);
    border-radius: 10000px;
  }
  100% {
    background-color: var(--success-color);
    width: var(--btn-height);
    border-radius: 10000px;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.login-appear {
  opacity: 0;
}
.login-appear-active {
  opacity: 1;
  transition: opacity 200ms;
}
.login-enter {
  opacity: 0;
}
.login-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.login-exit {
  opacity: 1;
}
.login-exit-active {
  opacity: 0;
  scale: 0.3;
  transition: opacity 200ms, scale 200ms;
}
