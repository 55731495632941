@tailwind base;
@tailwind components;
@tailwind utilities;

[data-theme="mytheme"] .btn {
  border-width: 20px;
  border-color: black;
}

.verticalNavBar-enter {
  opacity: 0.3;
  transform: translate(-80px, 0);
  z-index: 1;
}
.verticalNavBar-enter.verticalNavBar-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 500ms ease;
}

.horizontalNavBar-enter {
  opacity: 0.3;
  transform: translate(0, -56px);
  z-index: 1;
}

.horizontalNavBar-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 150ms ease-out 250ms, transform 500ms ease 250ms;
}

.mainContent-enter {
  opacity: 0;
  scale: 0.9;
  z-index: 1;
}
.mainContent-enter.mainContent-enter-active {
  opacity: 1;
  scale: 1;
  transition: opacity 500ms ease-out 600ms, scale 500ms ease-out 600ms;
}
